import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://6dd52754079c2c8704d44b5f035f59a5@sty.truemote.com/2",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1,
});